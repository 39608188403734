<template>
  <div class="login-views">
    <div class="title-area">
      <img src="@/assets/brand_logo.png" />
    </div>
    <div class="columns">
      <div class="column"></div>
      <div class="column is-one-third">
        <div class="box">
          <form @submit.prevent="login">
            <div class="field">
              <div class="label">メールアドレス</div>
              <input class="input" type="email" placeholder="user@example.com" v-model="username" required
                autocomplete="email" />
            </div>
            <div class="field">
              <div class="label">パスワード</div>
              <input class="input" type="password" placeholder="*******" v-model="password" required
                autocomplete="current-password" />
            </div>
            <button class="button is-info is-fullwidth">ログイン</button>
            <div class="notification is-danger is-light" v-if="notAuthorized">
              <strong>メールアドレスまたはパスワードが間違っています。</strong>
              <p>ご確認の上再度お試しください。</p>
            </div>
            <div v-if="isNewPasswordRequired">
              <div class="notification is-warning mt-3">
                <strong>新しいパスワードを設定します。</strong>
              </div>
              <div class="field">
                <div class="label">新パスワード</div>
                <input class="input" type="password" placeholder="*******" v-model="newPassword" required
                  autocomplete="new-password" />
                <div class="label">確認のため再度入力してください。</div>
                <input class="input" type="password" placeholder="*******" v-model="newPasswordConfirm" required
                  autocomplete="new-password" />
              </div>
              <button class="button is-info is-fullwidth">
                パスワードを設定してログイン
              </button>
            </div>
            <button type="button" class="forgot-password is-size-7" @click="isPasswordForgotten = true">
              パスワードをお忘れの方は
            </button>
            <div class="modal" :class="{ 'is-active': isPasswordForgotten }">
              <div class="modal-background"></div>
              <div class="modal-content">
                <form class="box">
                  <p>再設定用のパスワードを送信します。</p>
                  <p>メールアドレスを入力してください</p>
                  <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                      <input class="input" type="email" placeholder="user@example.com"
                        v-model="emailForPasswordForgotten" />
                    </div>
                  </div>
                  <button class="button is-primary" @click="sendPasswordMail" type="button">
                    送信する
                  </button>
                  <!-- ここに検証コードとパスワード再設定用のフィールドをv-ifで追加 -->
                  <div v-if="isOpenValidationArea">
                    <div class="field">
                      <label class="label">検証用コード</label>
                      <div class="control">
                        <input class="input" type="text" v-model="validationCode" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">新しいパスワード</label>
                      <div class="control">
                        <input class="input" type="password" placeholder="*******" v-model="newPassword" required
                          autocomplete="new-password" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">新しいパスワードの確認</label>
                      <div class="control">
                        <input class="input" type="password" placeholder="*******" v-model="newPasswordConfirm" required
                          autocomplete="new-password" />
                      </div>
                    </div>
                    <button class="button is-primary" @click="resetPassword()" type="button">
                      パスワードを再設定してログイン
                    </button>
                  </div>
                </form>
              </div>
              <button type="button" class="modal-close is-large" aria-label="close"
                @click="isPasswordForgotten = false"></button>
            </div>
          </form>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      notAuthorized: false,
      isNewPasswordRequired: false,
      newPassword: "",
      newPasswordConfirm: "",
      isNewPasswordsDifferent: false,
      isPasswordForgotten: false,
      emailForPasswordForgotten: "",
      isOpenValidationArea: false,
      validationCode: null,
    };
  },
  methods: {
    login() {
      this.$cognito
        .login(this.username, this.password, this.newPassword)
        .then((result) => {
          console.log(result);
          this.$router.push({ path: "/" });
        })
        // .then(err => {
        //   console.log(err)
        //   alert("here")
        // })
        .catch((err) => {
          console.log(err.name);
          console.log(err.message);
          if (err.name == "NotAuthorizedException") this.notAuthorized = true;
          if (err == "NewPasswordRequired") {
            this.isNewPasswordRequired = true;
          }
        });
    },
    setNewPassword() {
      if (this.newPassword != this.newPasswordConfirm) {
        this.isNewPasswordsDifferent = true;
      }
    },
    sendPasswordMail() {
      this.$cognito
        .forgetPassword(this.emailForPasswordForgotten)
        .then(() => {
          this.isOpenValidationArea = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetPassword() {
      if (this.newPassword == this.newPasswordConfirm) {
        console.log(this.emailForPasswordForgotten)
        console.log(this.newPassword)
        console.log(this.validationCode)
        this.$cognito.resetPassword(this.emailForPasswordForgotten, this.newPassword, this.validationCode)
          .then(() => {
            this.$cognito.login(this.emailForPasswordForgotten, this.newPassword)
              .then(() => {
                this.$router.push({ path: "/" });
              })
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  },
};
</script>
<style scoped>
.login-views {
  background-color: #f7f7f7;
  /* text-align: center; */
  min-height: 100vh;
}

.title-area {
  text-align: center;
  display: inline-block;
  box-sizing: content-box;
}

.login-area {
  width: 60vmin;
  height: 60vmin;
  text-align: center;
  background-color: #ffffff;
}

form {
  width: 100%;
}

.label {
  text-align: left;
}

.forgot-password {
  margin-top: 20px;
}
</style>